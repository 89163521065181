
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "en"
]

export const localeLoaders = {
  "nl": [{ key: "../config/language/nl.js", load: () => import("../config/language/nl.js" /* webpackChunkName: "locale__vercel_path0_config_language_nl_js" */), cache: true }],
  "en": [{ key: "../config/language/en.js", load: () => import("../config/language/en.js" /* webpackChunkName: "locale__vercel_path0_config_language_en_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "__i18n_config_js_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.js",
  "locales": [
    {
      "code": "nl",
      "files": [
        "/vercel/path0/config/language/nl.js"
      ]
    },
    {
      "code": "en",
      "files": [
        "/vercel/path0/config/language/en.js"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "config/language/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "contact": {
      "en": "/contact",
      "nl": "/contact"
    },
    "gallery": {
      "en": "/gallery",
      "nl": "/galerij"
    },
    "index": {
      "en": "/",
      "nl": "/"
    },
    "sitemap": {
      "en": "/sitemap",
      "nl": "/sitemap"
    },
    "menus": {
      "en": "/menus",
      "nl": "/menus"
    },
    "groups": {
      "en": "/groups",
      "nl": "/groepen"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "nl",
    "files": [
      {
        "path": "/vercel/path0/config/language/nl.js"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "/vercel/path0/config/language/en.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
